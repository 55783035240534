// src/pages/About.js
import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const GenerativeAi = () => {
    const [isScrollingDown, setIsScrollingDown] = useState(false);
  let lastScrollTop = 0;

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop > lastScrollTop) {
        // Scrolling down, hide the header
        setIsScrollingDown(true);
      } else {
        // Scrolling up, show the header
        setIsScrollingDown(false);
      }

      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // Prevent negative scrolling
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div> 
        {/* Apply classes for smooth transitions */}
        <div className={`header-wrapper ${isScrollingDown ? 'hide' : 'show'}`}>
            <Header />
        </div>
        <section style={{background:'#E2FEFF',height:'100vh'}} className="container-fluid px-5 py-4  mb-5 insightpxnone" id="section1">
                        <div className='row d-flex mt-5'>
                            <div className='col-lg-4'>
                                <p className='ibm fw-300 fs-50 mb-0 insightHeading'style={{color: '#161616'}}>Insights</p> 
                                <p className='ibm fw-400 fs-17 insightPara'style={{color: '#16161680'}}>We leverage the latest scientific advancements and business strategies to guide our clients towards a sustainable future.</p> 
                                <button
                                    style={{
                                        background: '#00DFE6', 
                                        color: '#FFFFFF',
                                        border: 'none',
                                        padding: '5px 15px',
                                        position: 'relative',
                                        overflow: 'hidden',
                                        marginTop:'15px'
                                    }}
                                    type="button"
                                    className="ibm fs-16 fw-500 hverBtn generativeAiBtn"
                                    >
                                    Leverage AI For Sustainability 
                                    <span className="arrow">&#x2192;</span>
                                </button>

                            </div>
                            <div className='col-lg-8 mt-3'>
                            <img className='img-fluid w-100 generativebgimage' src='image/generativeAiBgImg.svg' alt='HomeImg'/>
                            </div>
                        </div>
        </section>   
        <section className='px-5 insightpxnone' style={{marginTop:'2%'}}>
            <div className='row'>
                <div className='col-lg-3 mb-2'>
                  <p className='ibm fw-400 fs-35 mb-0 insightSec1Header'style={{color: '#161616',position:'sticky',top:'10%' }}>Featured Insights</p> 
                </div>
                <div className='col-lg-9'>
                  <div className='row g-0'>
                    <div className="col-lg-4 hover-container-ai">
                            <img className='imageFluid-ai w-100' src='image/insideImage.svg' alt='Icon2' />
                            <div className='col-3-boxforcareer-ai'>
                                <p className="ibm fs-20 fw-400 mt-3 generativeSec2heading" style={{color:'#353535'}}>CEO’s Guide to generative AI: IT Automation </p>
                                <p className="ibm fs-16 fw-400 generativeSec2para" style={{color:'#353535', textAlign:'left'}}>CEOs are used to being in the hot seat. Only the toughest problems land on their desks—and how those problems are solved often defines an organization’s future.</p>
                                <p className="ibm fs-16 fw-400 generativeSec2para" style={{color:'#353535', textAlign:'left'}}>CEOs are used to being in the hot seat. CEOs are used to being in the hot seat. </p>
                                <a style={{color:'#151515',outline:'none'}} href='generative-ai-details' className="btn ibm fs-14 mt-5">
                                    <span className="learn-more">Learn more</span>
                                    <span style={{ fontSize: '20px' }} className='arrowforbuilding-ai'>&#x2192;</span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 hover-container-ai">
                            <img  className='imageFluid-ai w-100' src='image/insideImage.svg' alt='Icon2' />
                            <div className='col-3-boxforcareer-ai'>
                                <p className="ibm fs-20 fw-400 mt-3 generativeSec2heading" style={{color:'#353535'}}>CEO’s Guide to generative AI: IT Automation </p>
                                <p className="ibm fs-16 fw-400 generativeSec2para" style={{color:'#353535', textAlign:'left'}}>CEOs are used to being in the hot seat. Only the toughest problems land on their desks—and how those problems are solved often defines an organization’s future.</p>
                                <p className="ibm fs-16 fw-400 generativeSec2para" style={{color:'#353535', textAlign:'left'}}>CEOs are used to being in the hot seat. CEOs are used to being in the hot seat. </p>
                                <button style={{color:'#151515'}} type="button" className="btn ibm fs-14 mt-5">
                                    <span className="learn-more">Learn more</span>
                                    <span style={{ fontSize: '20px' }} className='arrowforbuilding-ai'>&#x2192;</span>
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-4 hover-container-ai">
                            <img className='imageFluid-ai w-100' src='image/insideImage.svg' alt='Icon3' />
                            <div className='col-3-boxforcareer-ai'>
                                <p className="ibm fs-20 fw-400 mt-3 generativeSec2heading" style={{color:'#353535'}}>CEO’s Guide to generative AI: IT Automation </p>
                                <p className="ibm fs-16 fw-400 generativeSec2para" style={{color:'#353535', textAlign:'left'}}>CEOs are used to being in the hot seat. Only the toughest problems land on their desks—and how those problems are solved often defines an organization’s future.</p>
                                <p className="ibm fs-16 fw-400 generativeSec2para" style={{color:'#353535', textAlign:'left'}}>CEOs are used to being in the hot seat. CEOs are used to being in the hot seat. </p>
                                <button style={{color:'#151515'}} type="button" className="btn ibm fs-14 mt-5">
                                    <span className="learn-more">Learn more</span>
                                    <span style={{ fontSize: '20px' }} className='arrowforbuilding-ai'>&#x2192;</span>
                                </button>
                            </div>
                        </div>
                  </div>
                  <div className='row g-0'>
                    <div className="col-lg-4 hover-container-ai">
                            <img className='imageFluid-ai w-100' src='image/insideImage.svg' alt='Icon2' />
                            <div className='col-3-boxforcareer-ai'>
                                <p className="ibm fs-20 fw-400 mt-3 generativeSec2heading" style={{color:'#353535'}}>CEO’s Guide to generative AI: IT Automation </p>
                                <p className="ibm fs-16 fw-400 generativeSec2para" style={{color:'#353535', textAlign:'left'}}>CEOs are used to being in the hot seat. Only the toughest problems land on their desks—and how those problems are solved often defines an organization’s future.</p>
                                <p className="ibm fs-16 fw-400 generativeSec2para" style={{color:'#353535', textAlign:'left'}}>CEOs are used to being in the hot seat. CEOs are used to being in the hot seat. </p>
                                <a style={{color:'#151515',outline:'none'}} href='generative-ai-details' className="btn ibm fs-14 mt-5">
                                    <span className="learn-more">Learn more</span>
                                    <span style={{ fontSize: '20px' }} className='arrowforbuilding-ai'>&#x2192;</span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 hover-container-ai">
                            <img  className='imageFluid-ai w-100' src='image/insideImage.svg' alt='Icon2' />
                            <div className='col-3-boxforcareer-ai'>
                                <p className="ibm fs-20 fw-400 mt-3 generativeSec2heading" style={{color:'#353535'}}>CEO’s Guide to generative AI: IT Automation </p>
                                <p className="ibm fs-16 fw-400 generativeSec2para" style={{color:'#353535', textAlign:'left'}}>CEOs are used to being in the hot seat. Only the toughest problems land on their desks—and how those problems are solved often defines an organization’s future.</p>
                                <p className="ibm fs-16 fw-400 generativeSec2para" style={{color:'#353535', textAlign:'left'}}>CEOs are used to being in the hot seat. CEOs are used to being in the hot seat. </p>
                                <button style={{color:'#151515'}} type="button" className="btn ibm fs-14 mt-5">
                                    <span className="learn-more">Learn more</span>
                                    <span style={{ fontSize: '20px' }} className='arrowforbuilding-ai'>&#x2192;</span>
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-4 hover-container-ai">
                            <img className='imageFluid-ai w-100' src='image/insideImage.svg' alt='Icon3' />
                            <div className='col-3-boxforcareer-ai'>
                                <p className="ibm fs-20 fw-400 mt-3 generativeSec2heading" style={{color:'#353535'}}>CEO’s Guide to generative AI: IT Automation </p>
                                <p className="ibm fs-16 fw-400 generativeSec2para" style={{color:'#353535', textAlign:'left'}}>CEOs are used to being in the hot seat. Only the toughest problems land on their desks—and how those problems are solved often defines an organization’s future.</p>
                                <p className="ibm fs-16 fw-400 generativeSec2para" style={{color:'#353535', textAlign:'left'}}>CEOs are used to being in the hot seat. CEOs are used to being in the hot seat. </p>
                                <button style={{color:'#151515'}} type="button" className="btn ibm fs-14 mt-5">
                                    <span className="learn-more">Learn more</span>
                                    <span style={{ fontSize: '20px' }} className='arrowforbuilding-ai'>&#x2192;</span>
                                </button>
                            </div>
                        </div>
                  </div>
                  <div className='row g-0'>
                    <div className="col-lg-4 hover-container-ai">
                            <img className='imageFluid-ai w-100' src='image/insideImage.svg' alt='Icon2' />
                            <div className='col-3-boxforcareer-ai'>
                                <p className="ibm fs-20 fw-400 mt-3 generativeSec2heading" style={{color:'#353535'}}>CEO’s Guide to generative AI: IT Automation </p>
                                <p className="ibm fs-16 fw-400 generativeSec2para" style={{color:'#353535', textAlign:'left'}}>CEOs are used to being in the hot seat. Only the toughest problems land on their desks—and how those problems are solved often defines an organization’s future.</p>
                                <p className="ibm fs-16 fw-400 generativeSec2para" style={{color:'#353535', textAlign:'left'}}>CEOs are used to being in the hot seat. CEOs are used to being in the hot seat. </p>
                                <a style={{color:'#151515',outline:'none'}} href='generative-ai-details' className="btn ibm fs-14 mt-5">
                                    <span className="learn-more">Learn more</span>
                                    <span style={{ fontSize: '20px' }} className='arrowforbuilding-ai'>&#x2192;</span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 hover-container-ai">
                            <img  className='imageFluid-ai w-100' src='image/insideImage.svg' alt='Icon2' />
                            <div className='col-3-boxforcareer-ai'>
                                <p className="ibm fs-20 fw-400 mt-3 generativeSec2heading" style={{color:'#353535'}}>CEO’s Guide to generative AI: IT Automation </p>
                                <p className="ibm fs-16 fw-400 generativeSec2para" style={{color:'#353535', textAlign:'left'}}>CEOs are used to being in the hot seat. Only the toughest problems land on their desks—and how those problems are solved often defines an organization’s future.</p>
                                <p className="ibm fs-16 fw-400 generativeSec2para" style={{color:'#353535', textAlign:'left'}}>CEOs are used to being in the hot seat. CEOs are used to being in the hot seat. </p>
                                <button style={{color:'#151515'}} type="button" className="btn ibm fs-14 mt-5">
                                    <span className="learn-more">Learn more</span>
                                    <span style={{ fontSize: '20px' }} className='arrowforbuilding-ai'>&#x2192;</span>
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-4 hover-container-ai">
                            <img className='imageFluid-ai w-100' src='image/insideImage.svg' alt='Icon3' />
                            <div className='col-3-boxforcareer-ai'>
                                <p className="ibm fs-20 fw-400 mt-3 generativeSec2heading" style={{color:'#353535'}}>CEO’s Guide to generative AI: IT Automation </p>
                                <p className="ibm fs-16 fw-400 generativeSec2para" style={{color:'#353535', textAlign:'left'}}>CEOs are used to being in the hot seat. Only the toughest problems land on their desks—and how those problems are solved often defines an organization’s future.</p>
                                <p className="ibm fs-16 fw-400 generativeSec2para" style={{color:'#353535', textAlign:'left'}}>CEOs are used to being in the hot seat. CEOs are used to being in the hot seat. </p>
                                <button style={{color:'#151515'}} type="button" className="btn ibm fs-14 mt-5">
                                    <span className="learn-more">Learn more</span>
                                    <span style={{ fontSize: '20px' }} className='arrowforbuilding-ai'>&#x2192;</span>
                                </button>
                            </div>
                        </div>
                  </div>
                  
                </div>
            </div>
        </section>                 
        <section className='px-5 mb-5 insightpxnone' style={{marginTop:'8%'}}>
            <div className='row'>
                <div className='col-lg-3'>
                  <p className='ibm fw-400 fs-35 insightSec1Header'style={{color: '#161616',position:'sticky',top:'10%'}}>Latest Insights</p> 
                </div>
                <div className='col-lg-9'>
                <div className='row g-0'>
                    <div className="col-lg-4 hover-container-ai">
                            <img className='imageFluid-ai w-100' src='image/insideImage.svg' alt='Icon2' />
                            <div className='col-3-boxforcareer-ai'>
                                <p className="ibm fs-20 fw-400 mt-3 generativeSec2heading" style={{color:'#353535'}}>CEO’s Guide to generative AI: IT Automation </p>
                                <p className="ibm fs-16 fw-400 generativeSec2para" style={{color:'#353535', textAlign:'left'}}>CEOs are used to being in the hot seat. Only the toughest problems land on their desks—and how those problems are solved often defines an organization’s future.</p>
                                <p className="ibm fs-16 fw-400 generativeSec2para" style={{color:'#353535', textAlign:'left'}}>CEOs are used to being in the hot seat. CEOs are used to being in the hot seat. </p>
                                <a style={{color:'#151515',outline:'none'}} href='generative-ai-details' className="btn ibm fs-14 mt-5">
                                    <span className="learn-more">Learn more</span>
                                    <span style={{ fontSize: '20px' }} className='arrowforbuilding-ai'>&#x2192;</span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 hover-container-ai">
                            <img  className='imageFluid-ai w-100' src='image/insideImage.svg' alt='Icon2' />
                            <div className='col-3-boxforcareer-ai'>
                                <p className="ibm fs-20 fw-400 mt-3 generativeSec2heading" style={{color:'#353535'}}>CEO’s Guide to generative AI: IT Automation </p>
                                <p className="ibm fs-16 fw-400 generativeSec2para" style={{color:'#353535', textAlign:'left'}}>CEOs are used to being in the hot seat. Only the toughest problems land on their desks—and how those problems are solved often defines an organization’s future.</p>
                                <p className="ibm fs-16 fw-400 generativeSec2para" style={{color:'#353535', textAlign:'left'}}>CEOs are used to being in the hot seat. CEOs are used to being in the hot seat. </p>
                                <button style={{color:'#151515'}} type="button" className="btn ibm fs-14 mt-5">
                                    <span className="learn-more">Learn more</span>
                                    <span style={{ fontSize: '20px' }} className='arrowforbuilding-ai'>&#x2192;</span>
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-4 hover-container-ai">
                            <img className='imageFluid-ai w-100' src='image/insideImage.svg' alt='Icon3' />
                            <div className='col-3-boxforcareer-ai'>
                                <p className="ibm fs-20 fw-400 mt-3 generativeSec2heading" style={{color:'#353535'}}>CEO’s Guide to generative AI: IT Automation </p>
                                <p className="ibm fs-16 fw-400 generativeSec2para" style={{color:'#353535', textAlign:'left'}}>CEOs are used to being in the hot seat. Only the toughest problems land on their desks—and how those problems are solved often defines an organization’s future.</p>
                                <p className="ibm fs-16 fw-400 generativeSec2para" style={{color:'#353535', textAlign:'left'}}>CEOs are used to being in the hot seat. CEOs are used to being in the hot seat. </p>
                                <button style={{color:'#151515'}} type="button" className="btn ibm fs-14 mt-5">
                                    <span className="learn-more">Learn more</span>
                                    <span style={{ fontSize: '20px' }} className='arrowforbuilding-ai'>&#x2192;</span>
                                </button>
                            </div>
                        </div>
                  </div>
                  <div className='row g-0'>
                    <div className="col-lg-4 hover-container-ai">
                            <img className='imageFluid-ai w-100' src='image/insideImage.svg' alt='Icon2' />
                            <div className='col-3-boxforcareer-ai'>
                                <p className="ibm fs-20 fw-400 mt-3 generativeSec2heading" style={{color:'#353535'}}>CEO’s Guide to generative AI: IT Automation </p>
                                <p className="ibm fs-16 fw-400 generativeSec2para" style={{color:'#353535', textAlign:'left'}}>CEOs are used to being in the hot seat. Only the toughest problems land on their desks—and how those problems are solved often defines an organization’s future.</p>
                                <p className="ibm fs-16 fw-400 generativeSec2para" style={{color:'#353535', textAlign:'left'}}>CEOs are used to being in the hot seat. CEOs are used to being in the hot seat. </p>
                                <a style={{color:'#151515',outline:'none'}} href='generative-ai-details' className="btn ibm fs-14 mt-5">
                                    <span className="learn-more">Learn more</span>
                                    <span style={{ fontSize: '20px' }} className='arrowforbuilding-ai'>&#x2192;</span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 hover-container-ai">
                            <img  className='imageFluid-ai w-100' src='image/insideImage.svg' alt='Icon2' />
                            <div className='col-3-boxforcareer-ai'>
                                <p className="ibm fs-20 fw-400 mt-3 generativeSec2heading" style={{color:'#353535'}}>CEO’s Guide to generative AI: IT Automation </p>
                                <p className="ibm fs-16 fw-400 generativeSec2para" style={{color:'#353535', textAlign:'left'}}>CEOs are used to being in the hot seat. Only the toughest problems land on their desks—and how those problems are solved often defines an organization’s future.</p>
                                <p className="ibm fs-16 fw-400 generativeSec2para" style={{color:'#353535', textAlign:'left'}}>CEOs are used to being in the hot seat. CEOs are used to being in the hot seat. </p>
                                <button style={{color:'#151515'}} type="button" className="btn ibm fs-14 mt-5">
                                    <span className="learn-more">Learn more</span>
                                    <span style={{ fontSize: '20px' }} className='arrowforbuilding-ai'>&#x2192;</span>
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-4 hover-container-ai">
                            <img className='imageFluid-ai w-100' src='image/insideImage.svg' alt='Icon3' />
                            <div className='col-3-boxforcareer-ai'>
                                <p className="ibm fs-20 fw-400 mt-3 generativeSec2heading" style={{color:'#353535'}}>CEO’s Guide to generative AI: IT Automation </p>
                                <p className="ibm fs-16 fw-400 generativeSec2para" style={{color:'#353535', textAlign:'left'}}>CEOs are used to being in the hot seat. Only the toughest problems land on their desks—and how those problems are solved often defines an organization’s future.</p>
                                <p className="ibm fs-16 fw-400 generativeSec2para" style={{color:'#353535', textAlign:'left'}}>CEOs are used to being in the hot seat. CEOs are used to being in the hot seat. </p>
                                <button style={{color:'#151515'}} type="button" className="btn ibm fs-14 mt-5">
                                    <span className="learn-more">Learn more</span>
                                    <span style={{ fontSize: '20px' }} className='arrowforbuilding-ai'>&#x2192;</span>
                                </button>
                            </div>
                        </div>
                  </div>
                  <div className='row g-0'>
                    <div className="col-lg-4 hover-container-ai">
                            <img className='imageFluid-ai w-100' src='image/insideImage.svg' alt='Icon2' />
                            <div className='col-3-boxforcareer-ai'>
                                <p className="ibm fs-20 fw-400 mt-3 generativeSec2heading" style={{color:'#353535'}}>CEO’s Guide to generative AI: IT Automation </p>
                                <p className="ibm fs-16 fw-400 generativeSec2para" style={{color:'#353535', textAlign:'left'}}>CEOs are used to being in the hot seat. Only the toughest problems land on their desks—and how those problems are solved often defines an organization’s future.</p>
                                <p className="ibm fs-16 fw-400 generativeSec2para" style={{color:'#353535', textAlign:'left'}}>CEOs are used to being in the hot seat. CEOs are used to being in the hot seat. </p>
                                <a style={{color:'#151515',outline:'none'}} href='generative-ai-details' className="btn ibm fs-14 mt-5">
                                    <span className="learn-more">Learn more</span>
                                    <span style={{ fontSize: '20px' }} className='arrowforbuilding-ai'>&#x2192;</span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 hover-container-ai">
                            <img  className='imageFluid-ai w-100' src='image/insideImage.svg' alt='Icon2' />
                            <div className='col-3-boxforcareer-ai'>
                                <p className="ibm fs-20 fw-400 mt-3 generativeSec2heading" style={{color:'#353535'}}>CEO’s Guide to generative AI: IT Automation </p>
                                <p className="ibm fs-16 fw-400 generativeSec2para" style={{color:'#353535', textAlign:'left'}}>CEOs are used to being in the hot seat. Only the toughest problems land on their desks—and how those problems are solved often defines an organization’s future.</p>
                                <p className="ibm fs-16 fw-400 generativeSec2para" style={{color:'#353535', textAlign:'left'}}>CEOs are used to being in the hot seat. CEOs are used to being in the hot seat. </p>
                                <button style={{color:'#151515'}} type="button" className="btn ibm fs-14 mt-5">
                                    <span className="learn-more">Learn more</span>
                                    <span style={{ fontSize: '20px' }} className='arrowforbuilding-ai'>&#x2192;</span>
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-4 hover-container-ai">
                            <img className='imageFluid-ai w-100' src='image/insideImage.svg' alt='Icon3' />
                            <div className='col-3-boxforcareer-ai'>
                                <p className="ibm fs-20 fw-400 mt-3 generativeSec2heading" style={{color:'#353535'}}>CEO’s Guide to generative AI: IT Automation </p>
                                <p className="ibm fs-16 fw-400 generativeSec2para" style={{color:'#353535', textAlign:'left'}}>CEOs are used to being in the hot seat. Only the toughest problems land on their desks—and how those problems are solved often defines an organization’s future.</p>
                                <p className="ibm fs-16 fw-400 generativeSec2para" style={{color:'#353535', textAlign:'left'}}>CEOs are used to being in the hot seat. CEOs are used to being in the hot seat. </p>
                                <button style={{color:'#151515'}} type="button" className="btn ibm fs-14 mt-5">
                                    <span className="learn-more">Learn more</span>
                                    <span style={{ fontSize: '20px' }} className='arrowforbuilding-ai'>&#x2192;</span>
                                </button>
                            </div>
                        </div>
                  </div>
                  
                </div>
            </div>
        </section> 
        <Footer/>                
    </div>
  );
};

export default GenerativeAi;

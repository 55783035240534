import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from "../components/Footer";


const Home = () => {
    const [isScrollingDown, setIsScrollingDown] = useState(false);
  let lastScrollTop = 0;

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop > lastScrollTop) {
        // Scrolling down, hide the header
        setIsScrollingDown(true);
      } else {
        // Scrolling up, show the header
        setIsScrollingDown(false);
      }
      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; 
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
        <div className={`header-wrapper ${isScrollingDown ? 'hide' : 'show'}`}>
            <Header />
        </div>
        <div className="container-fluid px-5 pxfivenone">
            <section id="section1">
                <div className='row d-flex bannersectionmobile' style={{marginTop:'7%'}}>
                    <div className='col-lg-4'>
                       <div style={{lineHeight:'1.2'}}>
                       <p className='ibm fw-400 fs-55 mb-0 banner-fs-headding'style={{color: '#161616'}}>Helping you navigate your <span className='ibm fw-400 fs-55 mb-0 banner-fs-headding' style={{color: '#00DFE6'}}>sustainable endeavors.</span></p> 
                       </div>
                        <p className='ibm fw-400 fs-20 mt-4 banner-fs-paragraph' style={{color:'#16161680'}}>We leverage the latest scientific advancements and business strategies to guide our clients towards a sustainable future.</p>
                        <button
                            style={{
                                background: '#00DFE6',
                                color: '#FFFFFF',
                                border: 'none',
                                padding: '5px 15px',
                                position: 'relative', 
                                overflow: 'hidden' 
                            }}
                            type="button"
                            className="ibm fs-16 fw-500 hverBtn mt-2 banner-fs-btn"
                            >
                            Leverage AI For Sustainability 
                            <span className="arrow">&#x2192;</span>
                        </button>

                    </div>
                    <div className='col-lg-8 mt-3'>
                       <img style={{width:'100%'}} className='img-fluid' src='image/ai-image.svg' alt='HomeImg'/>
                    </div>
                </div>
            </section>
           <section id="section2">
                <div style={{marginTop:'7% '}}>
                    <div>
                        <p className='ibm fs-40 fw-400 mb-0 section2-fs-headding' style={{ color:'#161616'}}>Building a Sustainable Tomorrow, Together!</p>
                        <p className='ibm fs-17 fw-400 section2-fs-paragraph' style={{ color:'#161616'}}>We guide your journey towards a sustainable future, where we work together to address global challenges and create a better world for future generations"</p>
                    </div>
                </div>
                <div className='mt-4'>
                    {/* <Services/> */}
                    <div className='row ms-0 me-0'>
                            <div className='col-lg-4 border-container section2-box-height'>
                                <img className='ms-3 img-fluid' src='image/col3icon.svg' alt='Icon' />
                                {/* <hr className='ms-3 mb-5' style={{color: 'solid #00000033', width:'20%'}}></hr> */}
                                <div style={{color:'#141414',position: 'relative', overflow: 'hidden',paddingTop:'16%'}} >
                                    <span className="ibm fs-18 fw-400 default-text1">Sustainable Financing Solutions <img className='ml-0' src='image/rightArrow.svg' style={{ marginLeft:'18%'}} alt='Arrow'></img></span>
                                    <p className="ibm fs-18 fw-400 hover-text1 hover-text-container" style={{color:'#141414'}}>
                                        Strategic guidance on securing<br></br> sustainable financing
                                        <img src='image/rightArrow.svg' style={{ marginLeft:'8rem'}} className="arrowforbuilding" alt='Arrow'></img>
                                    </p>
                                </div>
                            </div>
                            <div className='col-lg-4 border-container section2-box-height'>
                                <img className='ms-3 img-fluid' src='image/col3icon.svg' alt='Icon' />
                                {/* <hr className='ms-3 mb-5' style={{color: 'solid #00000033', width:'20%'}}></hr> */}
                                <div style={{color:'#141414',position: 'relative', overflow: 'hidden',paddingTop:'16%'}} >
                                    <span className="ibm fs-18 fw-400 default-text1">Sustainability Blueprint<img className='ml-0' src='image/rightArrow.svg' style={{ marginLeft:'40%'}} alt='Arrow'></img></span>
                                    <p className="ibm fs-18 fw-400 hover-text1 hover-text-container" style={{color:'#141414'}}>
                                    Comprehensive sustainability<br></br> blueprint to guide your transition
                                        <img src='image/rightArrow.svg' style={{ marginLeft:'3rem'}} className="arrowforbuilding" alt='Arrow'></img>
                                    </p>
                                </div>
                            </div>
                            <div className='col-lg-4 border-container section2-box-height'>
                                <img className='ms-3 img-fluid' src='image/col3icon.svg' alt='Icon' />
                                {/* <hr className='ms-3 mb-5' style={{color: 'solid #00000033', width:'20%'}}></hr> */}
                                <div style={{color:'#141414',position: 'relative', overflow: 'hidden',paddingTop:'16%'}} >
                                    <span className="ibm fs-18 fw-400 default-text1">Sustainability Risk Assessment<img className='ml-0' src='image/rightArrow.svg' style={{ marginLeft:'22%'}} alt='Arrow'></img></span>
                                    <p className="ibm fs-18 fw-400 hover-text1 hover-text-container" style={{color:'#141414'}}>
                                    Evaluation of vulnerability to<br></br> climate-related disruptions
                                        <img src='image/rightArrow.svg' style={{ marginLeft:'6rem'}} className="arrowforbuilding" alt='Arrow'></img>
                                    </p>
                                </div>
                            </div>
                            <div className='col-lg-4 border-container section2-box-height'>
                                <img className='ms-3' src='image/col3icon.svg' alt='Icon' />
                                {/* <hr className='ms-3 mb-5' style={{color: 'solid #00000033', width:'20%'}}></hr> */}
                                <div style={{color:'#141414',position: 'relative', overflow: 'hidden',paddingTop:'16%'}} >
                                    <span className="ibm fs-18 fw-400 default-text1">Sustainability Reporting<img className='ml-0' src='image/rightArrow.svg' style={{ marginLeft:'37%'}} alt='Arrow'></img></span>
                                    <p className="ibm fs-18 fw-400 hover-text1 hover-text-container" style={{color:'#141414'}}>
                                    Strategies for sustainability<br></br> reporting for regulatory compliance
                                        <img src='image/rightArrow.svg' style={{ marginLeft:'2rem'}} className="arrowforbuilding" alt='Arrow'></img>
                                    </p>
                                </div>
                            </div>
                            <div className='col-lg-4 border-container section2-box-height'>
                                <img className='ms-3' src='image/col3icon.svg' alt='Icon' />
                                {/* <hr className='ms-3 mb-5' style={{color: 'solid #00000033', width:'20%'}}></hr> */}
                                <div style={{color:'#141414',position: 'relative', overflow: 'hidden',paddingTop:'16%'}} >
                                    <span className="ibm fs-18 fw-400 default-text1">Science Based Target<img className='ml-0' src='image/rightArrow.svg' style={{ marginLeft:'41%'}} alt='Arrow'></img></span>
                                    <p className="ibm fs-18 fw-400 hover-text1 hover-text-container" style={{color:'#141414'}}>
                                    Develop ambitious and measurable<br></br> Science-Based Targets (SBTs)
                                        <img src='image/rightArrow.svg' style={{ marginLeft:'5rem'}} className="arrowforbuilding" alt='Arrow'></img>
                                    </p>
                                </div>
                            </div>
                            <div className='col-lg-4 border-container section2-box-height'>
                                <img className='ms-3' src='image/col3icon.svg' alt='Icon' />
                                {/* <hr className='ms-3 mb-5' style={{color: 'solid #00000033', width:'20%'}}></hr> */}
                                <div style={{color:'#141414',position: 'relative', overflow: 'hidden',paddingTop:'16%'}} >
                                    <span className="ibm fs-18 fw-400 default-text1">Continuous Improvement<img className='ml-0' src='image/rightArrow.svg' style={{ marginLeft:'34%'}} alt='Arrow'></img></span>
                                    <p className="ibm fs-18 fw-400 hover-text1 hover-text-container" style={{color:'#141414'}}>
                                    Strategies for sustainable growth<br></br> through continuous improvement
                                        <img src='image/rightArrow.svg' style={{ marginLeft:'3rem'}} className="arrowforbuilding" alt='Arrow'></img>
                                    </p>
                                    
                                </div>
                            </div>
                    </div> 
                </div>
           </section>
           <section id="section3">
              <div style={{marginTop:'5%'}}>
                <p className='ibm fw-400 fs-40 mb-0 solutionmobileview section2-fs-headding' style={{color:'#161616'}}>A Sustainable Solution, Uniquely Yours!</p>
                <p className='ibm fw-400 fs-17 section2-fs-paragraph' style={{color:'#161616'}}>A bespoke sustainability solutions driven by innovation, guided by expert advice, and delivering measurable results</p>
              </div>
              <div className='mt-4'>
                <div className='row g-0'>
                    {/* Card 1 */}
                    <div className='col-lg-3'>
                        <div className="hover-container w-100">
                            <img className='w-100 img-fluid imageFluid' src='image/insideImage.svg' alt='Icon2' />
                            <div className='col-3-boxforcareer'>
                            <p className="ibm fs-20 ms-2 pt-4 section3-fs-headding" style={{ color: '#7B7B7B' }}>Assess</p>
                            <p className="ibm fs-18 ms-2 section3-fs-paragraph" style={{ color: '#353535', textAlign: 'left' }}>
                                We provide a comprehensive evaluation of your business's environmental footprint
                            </p>
                            <a style={{ color: '#151515',marginTop: '8%' }} href='comming-soon' className="btn ibm fs-15">
                                <span className="learn-more">Learn more</span>
                                <span style={{ fontSize: '20px' }} className='arrowforbuilding123'>&#x2192;</span>
                            </a>
                            </div>
                        </div>
                    </div>
                    {/* Card 2 */}
                    <div className='col-lg-3 '>
                        <div className="hover-container w-100">
                            <img className='w-100 img-fluid imageFluid' src='image/insideImage.svg' alt='Icon2' />
                            <div className='col-3-boxforcareer'>
                            <p className="ibm fs-20 ms-2 pt-4 section3-fs-headding" style={{ color: '#7B7B7B' }}>Plan</p>
                            <p className="ibm fs-18 ms-2 section3-fs-paragraph" style={{ color: '#353535', textAlign: 'left' }}>
                                We partner with you to create a clear roadmap for your sustainability journey.
                            </p>
                            <a style={{ color: '#151515',marginTop: '8%' }} href='comming-soon' className="btn ibm fs-15">
                                <span className="learn-more">Learn more</span>
                                <span style={{ fontSize: '20px' }} className='arrowforbuilding123'>&#x2192;</span>
                            </a>
                            </div>
                        </div>
                    </div>
                    {/* Card 3 */}
                    <div className='col-lg-3 '>
                        <div className="hover-container w-100">
                            <img className='w-100 img-fluid imageFluid' src='image/insideImage.svg' alt='Icon3' />
                            <div className='col-3-boxforcareer'>
                            <p className="ibm fs-20 ms-2 pt-4 section3-fs-headding" style={{ color: '#7B7B7B' }}>Execute</p>
                            <p className="ibm fs-18 ms-2 section3-fs-paragraph" style={{ color: '#353535', textAlign: 'left' }}>
                                We implement strategies that substantially lower your environmental footprint.
                            </p>
                            <a style={{ color: '#151515',marginTop: '8%' }} href='comming-soon' className="btn ibm fs-15">
                                <span className="learn-more">Learn more</span>
                                <span style={{ fontSize: '20px' }} className='arrowforbuilding123'>&#x2192;</span>
                            </a>
                            </div>
                        </div>
                    </div>
                    {/* Card 4 */}
                    <div className='col-lg-3 '>
                        <div className="hover-container w-100">
                            <img className='w-100 img-fluid imageFluid' src='image/insideImage.svg' alt='Icon2' />
                            <div className='col-3-boxforcareer'>
                            <p className="ibm fs-20 ms-2 pt-4 section3-fs-headding" style={{ color: '#7B7B7B' }}>Innovate</p>
                            <p className="ibm fs-18 ms-2 section3-fs-paragraph" style={{ color: '#353535', textAlign: 'left' }}>
                                We help you stay ahead by embracing innovation in your sustainability efforts.
                            </p>
                            <a href='/about' className="btn ibm fs-15" style={{ color: '#151515',marginTop: '8%' }}>
                                <span className="learn-more">Learn more</span>
                                <span style={{ fontSize: '20px' }} className='arrowforbuilding123'>&#x2192;</span>
                            </a>
                            </div>
                        </div>
                    </div>
                 </div>
                </div>
           </section>
        </div>
        <section id="section4">
            <div style={{background:'#F3F3F3', marginTop:'5%',overflow:'hidden'}}>
                <div className='row'>
                    <div className='col-lg-7'style={{padding:'15px 40px'}}>
                        <p className="ibm fs-18 section4-fs-headding"  style={{color:'#525252', textAlign:'left'}}>The latest from Sustainometry</p>
                        <p className="ibm fs-30 fw-600 section4-fs-paragraph"  style={{color:'#303030', textAlign:'left'}}>Lorem ipsum dolor sit amet consectetur. Vestibulum convallis sed id euismod vel orci egestas.</p>
                        <p className="ibm fs-16 fw-400 section4-fs-paragraph2"  style={{color:'#00000078', textAlign:'left'}}>Lorem ipsum dolor sit amet consectetur. Cursus aenean nec gravida fermentum proin egestas dignissim. Et leo ultrices vitae vitae sapien purus. Vel integer pulvinar amet in tortor pellentesque enim feugiat ipsum. Tristique dui dolor et nulla rhoncus. At neque massa suscipit lobortis risus aliquam fringilla. Netus felis a ac leo rhoncus. Ante amet nisl lectus eu.</p>
                    </div>
                    <div  className='col-lg-5 mt-5'>
                        <img style={{width:'100%'}} src='image/girls-ai.svg' alt='AI' className='img-fluid' />
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
    </div>
  );
};

export default Home;

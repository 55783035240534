// src/pages/Career.js
import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';




const GenerativeAiDetails = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const sectionsdetails = [
        { id: 'introduction', label: 'Introduction' },
        { id: 'role', label: 'Role and Responsibilities' },
        { id: 'technical', label: 'Technical and Professional Expertise' },
        { id: 'preferred', label: 'Preferred Technical Expertise' },
    ];
    const [isScrollingDown, setIsScrollingDown] = useState(false);
    let lastScrollTop = 0;

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop > lastScrollTop) {
        // Scrolling down, hide the header
        setIsScrollingDown(true);
      } else {
        // Scrolling up, show the header
        setIsScrollingDown(false);
      }

      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; 

    }
    
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);

    };
  }, []);

  return (
    <div> 
       {/* Apply classes for smooth transitions */}
       <div className={`header-wrapper ${isScrollingDown ? 'hide' : 'show'}`}>
        <Header />
      </div>
        <section style={{background:'#E2FEFF'}} className="px-5 py-4  mb-5 cdpx-remove" id="section1">
                        <div className='row mt-5 pb-3'>
                            <div className='col-lg-4 mt-3'>
                                <p  className='ibm fw-300 fs-45 genrativeAiDetailsHeading 'style={{color: '#161616',lineHeight:'1'}}>What is conversational AI?</p> 
                                <p className='ibm fw-400 fs-17 mt-4 genrativeAiDetailsPara 'style={{color: '#16161680'}}>We leverage the latest scientific advancements and business strategies to guide our clients towards a sustainable future.</p> 
                                <button
                            style={{
                                background: '#00DFE6',
                                color: '#FFFFFF',
                                border: 'none',
                                padding: '5px 15px',
                                position: 'relative', 
                                overflow: 'hidden' 
                            }}
                            type="button"
                            className="ibm fs-16 fw-500 hverBtn mt-3 careerDetailsBtn"
                            >
                            Leverage AI For Sustainability 
                            <span className="arrow">&#x2192;</span>
                        </button>

                            </div>
                            <div className='col-lg-8 mt-3'>
                            <img className='img-fluid generativeAiDetailsBtnMt' style={{width:'100%'}} src='image/ai-image.svg' alt='HomeImg'/>
                            </div>
                        </div>
        </section>   
        <section className='px-5 mb-5 genrativeAiDetailsPxremove'>
            <div className='row'>
                <div className='col-lg-3 order-2 order-lg-1 mt-2'>
                    <div className="navigation-container-details">
                        {sectionsdetails.map((section1, index) => (
                            <div key={section1.id} className={`button-container-details ${index <= activeIndex ? 'active' : ''}`}>
                                <button
                                    className={`nav-button-details ${index === activeIndex ? 'active-button-details' : ''}`}
                                    onClick={() => {
                                    setActiveIndex(index);
                                    document.getElementById(section1.id).scrollIntoView({ behavior: 'smooth' });
                                    }}
                                >
                                    {section1.label}
                                </button>
                            </div>
                        ))}
                        
                    </div>               
                </div>
                <div className='col-lg-6 order-3 order-lg-2'>
                    <div id="introduction" style={{ borderBottom: '1px solid #D9D9D9'}}>
                        <p className='ibm fw-400 fs-40 careerDetailsfsmintro' style={{ color: '#2F2F2F' }}>Introduction</p>
                        <p className='ibm fw-400 fs-16 careerDetailsfsPara'style={{color: '#5A5A5A'}}>Lorem ipsum dolor sit amet consectetur. Semper venenatis rhoncus amet lectus vitae molestie massa. Sagittis magna nec suscipit iaculis at cras aenean. Orci tortor nisl sem dolor. Malesuada aliquam scelerisque sit posuere felis. Pretium id urna metus ut at. Sagittis urna molestie magna eu ultrices feugiat enim ac sed. Amet justo risus ornare eget dignissim. Est lorem phasellus ornare auctor senectus. Ac in senectus bibendum ultrices donec egestas mattis. Eu urna bibendum posuere sed varius in habitant molestie lectus. Tristique et tristique posuere egestas auctor condimentum. Turpis sagittis nulla pharetra rhoncus magna gravida commodo laoreet diam. Nulla nulla risus tincidunt ac lacinia. Risus adipiscing in augue pretium velit in neque dictum. Mattis gravida hendrerit pretium ultrices eget velit. Lorem ullamcorper quam sit netus velit est. Turpis amet pharetra eget nunc montes dictum massa ut porttitor.Sapien adipiscing in a posuere nisi viverra integer magna. </p>
                        <p className='ibm fw-400 fs-16 careerDetailsfsPara'style={{color: '#5A5A5A'}}>Lorem ipsum dolor sit amet consectetur. Semper venenatis rhoncus amet lectus vitae molestie massa. Sagittis magna nec suscipit iaculis at cras aenean. Orci tortor nisl sem dolor. Malesuada aliquam scelerisque sit posuere felis. Pretium id urna metus ut at. Sagittis urna molestie magna eu ultrices feugiat enim ac sed. Amet justo risus ornare eget dignissim. Est lorem phasellus ornare auctor senectus. Ac in senectus bibendum ultrices donec egestas mattis. Eu urna bibendum posuere sed varius in habitant molestie lectus. Tristique et tristique posuere egestas auctor condimentum. Turpis sagittis nulla pharetra rhoncus magna gravida commodo laoreet diam. Nulla nulla risus tincidunt ac lacinia. Risus adipiscing in augue pretium velit in neque dictum. Rhoncus ut porttitor odio massa ut quisque sit ullamcorper.</p>
                    </div>
                    <div id="role" style={{ borderBottom: '1px solid #D9D9D9' }}>
                        <p className='ibm fw-400 fs-40 careerDetailsfsmintro' style={{ color: '#2F2F2F' }}>Role and Responsibilities</p>
                        <p className='ibm fw-400 fs-16 careerDetailsfsPara'style={{color: '#5A5A5A'}}>Lorem ipsum dolor sit amet consectetur. Semper venenatis rhoncus amet lectus vitae molestie massa. Sagittis magna nec suscipit iaculis at cras aenean. Orci tortor nisl sem dolor. Malesuada aliquam scelerisque sit posuere felis. Pretium id urna metus ut at. Sagittis urna molestie magna eu ultrices feugiat enim ac sed. Amet justo risus ornare eget dignissim. Est lorem phasellus ornare auctor senectus. Ac in senectus bibendum ultrices donec egestas mattis. Eu urna bibendum posuere sed varius in habitant molestie lectus. Tristique et tristique posuere egestas auctor condimentum. Turpis sagittis nulla pharetra rhoncus magna gravida commodo laoreet diam. Nulla nulla risus tincidunt ac lacinia. Risus adipiscing in augue pretium velit in neque dictum. Mattis gravida hendrerit pretium ultrices eget velit. Lorem ullamcorper quam sit netus velit est. Turpis amet pharetra eget nunc montes dictum massa ut porttitor.Sapien adipiscing in a posuere nisi viverra integer magna. </p>
                        <p className='ibm fw-400 fs-16 careerDetailsfsPara'style={{color: '#5A5A5A'}}>Lorem ipsum dolor sit amet consectetur. Semper venenatis rhoncus amet lectus vitae molestie massa. Sagittis magna nec suscipit iaculis at cras aenean. Orci tortor nisl sem dolor. Malesuada aliquam scelerisque sit posuere felis. Pretium id urna metus ut at. Sagittis urna molestie magna eu ultrices feugiat enim ac sed. Amet justo risus ornare eget dignissim. Est lorem phasellus ornare auctor senectus. Ac in senectus bibendum ultrices donec egestas mattis. Eu urna bibendum posuere sed varius in habitant molestie lectus. Tristique et tristique posuere egestas auctor condimentum. Turpis sagittis nulla pharetra rhoncus magna gravida commodo laoreet diam. Nulla nulla risus tincidunt ac lacinia. Risus adipiscing in augue pretium velit in neque dictum. Rhoncus ut porttitor odio massa ut quisque sit ullamcorper.</p>
                    </div>
                    <div id="technical" style={{ borderBottom: '1px solid #D9D9D9' }}>
                        <p className='ibm fw-400 fs-40 careerDetailsfsmintro' style={{ color: '#2F2F2F' }}>Required Technical Expertise</p>
                        <p className='ibm fw-400 fs-16 careerDetailsfsPara'style={{color: '#5A5A5A'}}>Lorem ipsum dolor sit amet consectetur. Semper venenatis rhoncus amet lectus vitae molestie massa. Sagittis magna nec suscipit iaculis at cras aenean. Orci tortor nisl sem dolor. Malesuada aliquam scelerisque sit posuere felis. Pretium id urna metus ut at. Sagittis urna molestie magna eu ultrices feugiat enim ac sed. Amet justo risus ornare eget dignissim. Est lorem phasellus ornare auctor senectus. Ac in senectus bibendum ultrices donec egestas mattis. Eu urna bibendum posuere sed varius in habitant molestie lectus. Tristique et tristique posuere egestas auctor condimentum. Turpis sagittis nulla pharetra rhoncus magna gravida commodo laoreet diam. Nulla nulla risus tincidunt ac lacinia. Risus adipiscing in augue pretium velit in neque dictum. Mattis gravida hendrerit pretium ultrices eget velit. Lorem ullamcorper quam sit netus velit est. Turpis amet pharetra eget nunc montes dictum massa ut porttitor.Sapien adipiscing in a posuere nisi viverra integer magna. </p>
                        <p className='ibm fw-400 fs-16 careerDetailsfsPara'style={{color: '#5A5A5A'}}>Lorem ipsum dolor sit amet consectetur. Semper venenatis rhoncus amet lectus vitae molestie massa. Sagittis magna nec suscipit iaculis at cras aenean. Orci tortor nisl sem dolor. Malesuada aliquam scelerisque sit posuere felis. Pretium id urna metus ut at. Sagittis urna molestie magna eu ultrices feugiat enim ac sed. Amet justo risus ornare eget dignissim. Est lorem phasellus ornare auctor senectus. Ac in senectus bibendum ultrices donec egestas mattis. Eu urna bibendum posuere sed varius in habitant molestie lectus. Tristique et tristique posuere egestas auctor condimentum. Turpis sagittis nulla pharetra rhoncus magna gravida commodo laoreet diam. Nulla nulla risus tincidunt ac lacinia. Risus adipiscing in augue pretium velit in neque dictum. Rhoncus ut porttitor odio massa ut quisque sit ullamcorper.</p>
                    </div>
                    <div id="preferred" style={{ borderBottom: '1px solid #D9D9D9' }}>
                        <p className='ibm fw-400 fs-40 careerDetailsfsmintro' style={{ color: '#2F2F2F' }}>Preferred Technical Expertise</p>
                        <p className='ibm fw-400 fs-16 careerDetailsfsPara'style={{color: '#5A5A5A'}}>Lorem ipsum dolor sit amet consectetur. Semper venenatis rhoncus amet lectus vitae molestie massa. Sagittis magna nec suscipit iaculis at cras aenean. Orci tortor nisl sem dolor. Malesuada aliquam scelerisque sit posuere felis. Pretium id urna metus ut at. Sagittis urna molestie magna eu ultrices feugiat enim ac sed. Amet justo risus ornare eget dignissim. Est lorem phasellus ornare auctor senectus. Ac in senectus bibendum ultrices donec egestas mattis. Eu urna bibendum posuere sed varius in habitant molestie lectus. Tristique et tristique posuere egestas auctor condimentum. Turpis sagittis nulla pharetra rhoncus magna gravida commodo laoreet diam. Nulla nulla risus tincidunt ac lacinia. Risus adipiscing in augue pretium velit in neque dictum. Mattis gravida hendrerit pretium ultrices eget velit. Lorem ullamcorper quam sit netus velit est. Turpis amet pharetra eget nunc montes dictum massa ut porttitor.Sapien adipiscing in a posuere nisi viverra integer magna. </p>
                        <p className='ibm fw-400 fs-16 careerDetailsfsPara'style={{color: '#5A5A5A'}}>Lorem ipsum dolor sit amet consectetur. Semper venenatis rhoncus amet lectus vitae molestie massa. Sagittis magna nec suscipit iaculis at cras aenean. Orci tortor nisl sem dolor. Malesuada aliquam scelerisque sit posuere felis. Pretium id urna metus ut at. Sagittis urna molestie magna eu ultrices feugiat enim ac sed. Amet justo risus ornare eget dignissim. Est lorem phasellus ornare auctor senectus. Ac in senectus bibendum ultrices donec egestas mattis. Eu urna bibendum posuere sed varius in habitant molestie lectus. Tristique et tristique posuere egestas auctor condimentum. Turpis sagittis nulla pharetra rhoncus magna gravida commodo laoreet diam. Nulla nulla risus tincidunt ac lacinia. Risus adipiscing in augue pretium velit in neque dictum. Rhoncus ut porttitor odio massa ut quisque sit ullamcorper.</p>
                    </div>
                    <div style={{borderBottom: '1px solid #D9D9D9',marginTop:'1rem'}}>
                        <p className='ibm fs-35' style={{color:'2F2F2F'}}>What it’s like to be an Sustainometrian?</p>
                        <img style={{width:'100%' , marginBottom:'2rem'}} src='image/insideImage.svg' alt='CareerDetailsImage' />
                    </div>
                </div>
                <div className='col-lg-3 order-1 order-lg-3' >
                    <div style={{position:'sticky',top:'12%'}}>
                        <img  src='image/harsh.svg' alt='HarshIcon' />
                        <div style={{lineHeight:'1'}}>
                            <p className='ibm fs-19 fw-500 mt-4 generativeAiUserSec2H' style={{color:'#2F2F2F'}}>Harsh Kumar</p>           
                            <p className='ibm fs-19 fw-500 generativeAiUserSec2H' style={{color:'#2F2F2F'}}>Ui/Ux Designer at Datametricks Private Ltd.</p>           
                            <p className='ibm fs-13 fw-400 generativeAiUserSec2P' style={{color:'#666666'}}>Noida, Uttar Pradesh, India</p>       
                        </div>
                    </div>    
                </div>
            </div>
        </section>
        <Footer/>                
    </div>
  );
};

export default GenerativeAiDetails;

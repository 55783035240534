// src/App.js
import './App.css';
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import Home from './pages/Home';
import Contact from './pages/Contact';
import Career from './pages/Career';
import About from './pages/About';
import GenerativeAi from './pages/GenerativeAi';
import CareerDetails from './pages/CareerDetails';
import CommingSoon from './pages/CommingSoon';
import GenerativeAiDetails from './pages/GenerativeAiDetails';




const App = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/generative-ai" element={<GenerativeAi />} />
          <Route path="/career" element={<Career />} />
          <Route path="/career-details" element={<CareerDetails />} />
          <Route path="/comming-soon" element={<CommingSoon />} />
          <Route path="/generative-ai-details" element={<GenerativeAiDetails />} />

        
        </Routes>
      </div>
    </Router>
  );
}

export default App;






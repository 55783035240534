import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';




const Career = () => {
    const [isScrollingDown, setIsScrollingDown] = useState(false);
  let lastScrollTop = 0;

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop > lastScrollTop) {
        // Scrolling down, hide the header
        setIsScrollingDown(true);
      } else {
        // Scrolling up, show the header
        setIsScrollingDown(false);
      }

      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // Prevent negative scrolling
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div> 
       <div className={`header-wrapper ${isScrollingDown ? 'hide' : 'show'}`}>
        <Header />
      </div>
        <section style={{background:'#E2FEFF'}} className="container-fluid px-5 py-4  mb-5 careerpxremove" id="section1">
                        <div className='row d-flex mt-5'>
                            <div className='col-lg-4'>
                                <p className='ibm fw-300 fs-50 mb-0 careerHeading'style={{color: '#161616'}}>Join us</p> 
                                <p className='ibm fw-400 fs-17 careerPara'style={{color: '#16161680'}}>Lorem ipsum dolor sit amet consectetur. Eget eu pulvinar maecenas orci urna ac ut. Sit urna dictum aenean dui. Suspendisse a quam neque faucibus diam cursus hendrerit ac. In gravida integer arcu in amet nunc malesuada duis massa. Phasellus sit suspendisse duis nibh vulputate consectetur. Blandit tempus id dui dignissim eu dolor augue eu. In cursus integer odio praesent pretium. Sagittis in arcu placerat dolor morbi adipiscing.</p> 
                            </div>
                            <div className='col-lg-8 mt-3'>
                            <img className='img-fluid careerbgimage' style={{width:'100%'}} src='image/careerBgImg.svg' alt='HomeImg'/>
                            </div>
                        </div>
        </section>   
        <section className='px-5 mb-5 careerpxremove'>
            <div className='row'>
                <div className='col-lg-3'>
                  <p className='ibm fw-400 fs-35 careersec2heading'style={{color: '#161616',top:'20px',position: 'sticky'}}>Open Positions</p> 
                </div>
                <div className='col-lg-9'>
                        <div className='row g-0'>
                            <div className="col-lg-4 hover-container-ai">
                                <img className='imageFluid-ai w-100' src='image/insideImage.svg' alt='Icon2' />
                                <div className='col-3-boxforcareer-ai'>
                                    <p className="ibm fs-15 fw-500 mt-4 careerSec3Para" style={{color:'#797979'}}>Germany</p>
                                    <p className="ibm fs-18 fw-500 careerSec3Heading" style={{color:'#353535'}}>Senior Sustainability Strategist</p>
                                    <p className="ibm fs-15 fw-400 careerSec3Para" style={{color:'#353535', textAlign:'left'}}>As a Senior Sustainability Strategist you will set up successful client relationship strategies to align our clients’ organization with planetary boundaries.</p>
                                    <p className="ibm fs-15 fw-400 careerSec3Para" style={{color:'#353535', textAlign:'left'}}>As a Senior Sustainability Strategist.</p>
                                    <a style={{color:'#151515'}} href='career-details' className="btn ibm fs-14">
                                        <span className="learn-more">Learn more</span>
                                        <span style={{ fontSize: '20px',paddingTop:'122px' }} className='arrowforbuilding-ai'>&#x2192;</span>
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-4 hover-container-ai">
                                <img  className='imageFluid-ai w-100' src='image/insideImage.svg' alt='Icon2' />
                                <div className='col-3-boxforcareer-ai'>
                                    <p className="ibm fs-15 fw-500 mt-4 careerSec3Para" style={{color:'#797979'}}>Germany</p>
                                    <p className="ibm fs-18 fw-500 careerSec3Heading" style={{color:'#353535'}}>Senior Sustainability Strategist</p>
                                    <p className="ibm fs-15 fw-400 careerSec3Para" style={{color:'#353535', textAlign:'left'}}>As a Senior Sustainability Strategist you will set up successful client relationship strategies to align our clients’ organization with planetary boundaries.</p>
                                    <p className="ibm fs-15 fw-400 careerSec3Para" style={{color:'#353535', textAlign:'left'}}>As a Senior Sustainability Strategist.</p>
                                    <button style={{color:'#151515'}} type="button" className="btn ibm fs-14">
                                        <span className="learn-more">Learn more</span>
                                        <span style={{ fontSize: '20px',paddingTop:'122px' }} className='arrowforbuilding-ai'>&#x2192;</span>
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-4 hover-container-ai">
                                <img className='imageFluid-ai w-100' src='image/insideImage.svg' alt='Icon3' />
                                <div className='col-3-boxforcareer-ai'>
                                    <p className="ibm fs-15 fw-500 mt-4 careerSec3Para" style={{color:'#797979'}}>Germany</p>
                                    <p className="ibm fs-18 fw-500 careerSec3Heading" style={{color:'#353535'}}>Senior Sustainability Strategist</p>
                                    <p className="ibm fs-15 fw-400 careerSec3Para" style={{color:'#353535', textAlign:'left'}}>As a Senior Sustainability Strategist you will set up successful client relationship strategies to align our clients’ organization with planetary boundaries.</p>
                                    <p className="ibm fs-15 fw-400 careerSec3Para" style={{color:'#353535', textAlign:'left'}}>As a Senior Sustainability Strategist.</p>
                                    <button style={{color:'#151515'}} type="button" className="btn ibm fs-14">
                                        <span className="learn-more">Learn more</span>
                                        <span style={{ fontSize: '20px',paddingTop:'122px' }} className='arrowforbuilding-ai'>&#x2192;</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='row g-0'>
                            <div className="col-lg-4 hover-container-ai">
                                <img className='imageFluid-ai w-100' src='image/insideImage.svg' alt='Icon2' />
                                <div className='col-3-boxforcareer-ai'>
                                    <p className="ibm fs-15 fw-500 mt-4 careerSec3Para" style={{color:'#797979'}}>Germany</p>
                                    <p className="ibm fs-18 fw-500 careerSec3Heading" style={{color:'#353535'}}>Senior Sustainability Strategist</p>
                                    <p className="ibm fs-15 fw-400 careerSec3Para" style={{color:'#353535', textAlign:'left'}}>As a Senior Sustainability Strategist you will set up successful client relationship strategies to align our clients’ organization with planetary boundaries.</p>
                                    <p className="ibm fs-15 fw-400 careerSec3Para" style={{color:'#353535', textAlign:'left'}}>As a Senior Sustainability Strategist.</p>
                                    <button style={{color:'#151515'}} type="button" className="btn ibm fs-14">
                                        <span className="learn-more">Learn more</span>
                                        <span style={{ fontSize: '20px',paddingTop:'122px' }} className='arrowforbuilding-ai'>&#x2192;</span>
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-4 hover-container-ai">
                                <img  className='imageFluid-ai w-100' src='image/insideImage.svg' alt='Icon2' />
                                <div className='col-3-boxforcareer-ai'>
                                    <p className="ibm fs-15 fw-500 mt-4 careerSec3Para" style={{color:'#797979'}}>Germany</p>
                                    <p className="ibm fs-18 fw-500 careerSec3Heading" style={{color:'#353535'}}>Senior Sustainability Strategist</p>
                                    <p className="ibm fs-15 fw-400 careerSec3Para" style={{color:'#353535', textAlign:'left'}}>As a Senior Sustainability Strategist you will set up successful client relationship strategies to align our clients’ organization with planetary boundaries.</p>
                                    <p className="ibm fs-15 fw-400 careerSec3Para" style={{color:'#353535', textAlign:'left'}}>As a Senior Sustainability Strategist.</p>
                                    <button style={{color:'#151515'}} type="button" className="btn ibm fs-14">
                                        <span className="learn-more">Learn more</span>
                                        <span style={{ fontSize: '20px',paddingTop:'122px' }} className='arrowforbuilding-ai'>&#x2192;</span>
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-4 hover-container-ai">
                                <img className='imageFluid-ai w-100' src='image/insideImage.svg' alt='Icon3' />
                                <div className='col-3-boxforcareer-ai'>
                                    <p className="ibm fs-15 fw-500 mt-4 careerSec3Para" style={{color:'#797979'}}>Germany</p>
                                    <p className="ibm fs-18 fw-500 careerSec3Heading" style={{color:'#353535'}}>Senior Sustainability Strategist</p>
                                    <p className="ibm fs-15 fw-400 careerSec3Para" style={{color:'#353535', textAlign:'left'}}>As a Senior Sustainability Strategist you will set up successful client relationship strategies to align our clients’ organization with planetary boundaries.</p>
                                    <p className="ibm fs-15 fw-400 careerSec3Para" style={{color:'#353535', textAlign:'left'}}>As a Senior Sustainability Strategist.</p>
                                    <button style={{color:'#151515'}} type="button" className="btn ibm fs-14">
                                        <span className="learn-more">Learn more</span>
                                        <span style={{ fontSize: '20px',paddingTop:'122px' }} className='arrowforbuilding-ai'>&#x2192;</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='row g-0'>
                            <div className="col-lg-4 hover-container-ai">
                                <img className='imageFluid-ai w-100' src='image/insideImage.svg' alt='Icon2' />
                                <div className='col-3-boxforcareer-ai'>
                                    <p className="ibm fs-15 fw-500 mt-4 careerSec3Para" style={{color:'#797979'}}>Germany</p>
                                    <p className="ibm fs-18 fw-500 careerSec3Heading" style={{color:'#353535'}}>Senior Sustainability Strategist</p>
                                    <p className="ibm fs-15 fw-400 careerSec3Para" style={{color:'#353535', textAlign:'left'}}>As a Senior Sustainability Strategist you will set up successful client relationship strategies to align our clients’ organization with planetary boundaries.</p>
                                    <p className="ibm fs-15 fw-400 careerSec3Para" style={{color:'#353535', textAlign:'left'}}>As a Senior Sustainability Strategist.</p>
                                    <button style={{color:'#151515'}} type="button" className="btn ibm fs-14">
                                        <span className="learn-more">Learn more</span>
                                        <span style={{ fontSize: '20px',paddingTop:'122px' }} className='arrowforbuilding-ai'>&#x2192;</span>
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-4 hover-container-ai">
                                <img  className='imageFluid-ai w-100' src='image/insideImage.svg' alt='Icon2' />
                                <div className='col-3-boxforcareer-ai'>
                                    <p className="ibm fs-15 fw-500 mt-4 careerSec3Para" style={{color:'#797979'}}>Germany</p>
                                    <p className="ibm fs-18 fw-500 careerSec3Heading" style={{color:'#353535'}}>Senior Sustainability Strategist</p>
                                    <p className="ibm fs-15 fw-400 careerSec3Para" style={{color:'#353535', textAlign:'left'}}>As a Senior Sustainability Strategist you will set up successful client relationship strategies to align our clients’ organization with planetary boundaries.</p>
                                    <p className="ibm fs-15 fw-400 careerSec3Para" style={{color:'#353535', textAlign:'left'}}>As a Senior Sustainability Strategist.</p>
                                    <button style={{color:'#151515'}} type="button" className="btn ibm fs-14">
                                        <span className="learn-more">Learn more</span>
                                        <span style={{ fontSize: '20px',paddingTop:'122px' }} className='arrowforbuilding-ai'>&#x2192;</span>
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-4 hover-container-ai">
                                <img className='imageFluid-ai w-100' src='image/insideImage.svg' alt='Icon3' />
                                <div className='col-3-boxforcareer-ai'>
                                    <p className="ibm fs-15 fw-500 mt-4 careerSec3Para" style={{color:'#797979'}}>Germany</p>
                                    <p className="ibm fs-18 fw-500 careerSec3Heading" style={{color:'#353535'}}>Senior Sustainability Strategist</p>
                                    <p className="ibm fs-15 fw-400 careerSec3Para" style={{color:'#353535', textAlign:'left'}}>As a Senior Sustainability Strategist you will set up successful client relationship strategies to align our clients’ organization with planetary boundaries.</p>
                                    <p className="ibm fs-15 fw-400 careerSec3Para" style={{color:'#353535', textAlign:'left'}}>As a Senior Sustainability Strategist.</p>
                                    <button style={{color:'#151515'}} type="button" className="btn ibm fs-14">
                                        <span className="learn-more">Learn more</span>
                                        <span style={{ fontSize: '20px',paddingTop:'122px' }} className='arrowforbuilding-ai'>&#x2192;</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </section>                 
        <Footer/>                
    </div>
  );
};

export default Career;

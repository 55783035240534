// src/pages/Contact.js
import React from 'react';

const Contact = () => {
  return (
    <div style={{ padding: '20px' }}>
      <h2>Contact Us</h2>
      <p>This is the Contact Us page.</p>
    </div>
  );
};

export default Contact;

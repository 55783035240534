
import React, { useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Header.css'; 
import { Navbar, Nav} from 'react-bootstrap';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa'; // Import arrow icons
// import { NavLink } from 'react-router-dom';

  const Header = () => {
    // =================Navbar mobile================
    const [activeIndex, setActiveIndex] = useState(null);
    const [expanded, setExpanded] = useState(false);

    const toggleItem = (index) => {
      setActiveIndex(activeIndex === index ? null : index);
    };
  
    const menuItems = [
      { 
        title: 'Products', 
        subcategories: [
          { name: 'Product 1', link: '#' },
          { name: 'Product 2', link: '#' },
          { name: 'Product 3', link: '#' }
        ]
      },
      { 
        title: 'Services', 
        subcategories: [
          { name: 'Service 1', link: '#' },
          { name: 'Service 2', link: '#' },
          { name: 'Service 3', link: '#' }
        ]
      },
      { 
        title: 'Consulting', 
        subcategories: [
          { name: 'Consulting 1', link: '#' },
          { name: 'Consulting 2', link: '#' }
        ]
      },
      { 
        title: 'Insight', 
        subcategories: [
          { name: 'Generative AI', link: '/generative-ai' }
        ]
      },
      { 
        title: 'Company', 
        subcategories: [
          { name: 'About', link: '/about-us' },
          { name: 'Career', link: '/career' }
        ]
      },
    ];

    const [isHovered, setIsHovered] = useState(false);
    const [isHovered1, setIsHovered1] = useState(false);
    const [isHoveredP, setIsHoveredProduct] = useState(false);
    const [isHoveredS, setIsHoveredService] = useState(false);
    const [isHoveredC, setIsHoveredConsulting] = useState(false);

    return (
      <div >
        {/* <header> */}
            <Navbar expand="lg" className="navbar-custom fixed-top px-5 pxfivenone" expanded={expanded}>
                
                    <Navbar.Brand href="/" className="navbar-brand-custom headerLogo">
                        Sustainometry
                    </Navbar.Brand>
                    <div className="vl"></div>
                    <Navbar.Toggle
                      aria-controls="basic-navbar-nav"
                      className="navbarstyle"
                      onClick={() => setExpanded(expanded ? false : "expanded")}
                    >
                      <span style={{ fontSize: '25px' }}>
                        {expanded ? '✕' : '☰'}
                      </span>
                    </Navbar.Toggle>
                    
                    <Navbar.Collapse id="basic-navbar-nav" className=''>
                      <div className='d-none d-sm-none d-lg-block d-md-block'>
                        <Nav className="ms-5">
                        <li className="nav-item active nav-item-custom12 dropdown-fs" onMouseEnter={() => setIsHoveredProduct(true)} // Set hover to true on mouse enter
                        onMouseLeave={() => setIsHoveredProduct(false)}>
                        <a className="nav-link" href="/">
                          Products
                          {isHoveredP ? (
                            <FaChevronUp style={{ marginLeft: '7px',fontSize:'15px' }} /> // Show up arrow on hover
                            ) : (
                            <FaChevronDown style={{ marginLeft: '7px',fontSize:'15px' }} /> // Show down arrow when not hovered
                          )}
                        </a>
                        
                        <div className="hover-container12 hover-container123">
                          <div style={{ display: 'flex' }}>
                            <div>
                              <img
                                src='image/menuImg.webp'
                                alt="Image1"
                                style={{ width: '200px', height: 'auto', marginRight: '20px' }} // Adjust the size as needed
                              />
                            </div>
                            
                            <div>
                              <a href="/" style={{ color: '#161616', textDecoration: 'none' }}>Products1</a><br></br>
                              <a href="/" style={{ color: '#161616', textDecoration: 'none' }}>Products2</a><br></br>
                              <a href="/" style={{ color: '#161616', textDecoration: 'none' }}>Products3</a>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="nav-item active nav-item-custom12 dropdown-fs ms-4"onMouseEnter={() => setIsHoveredService(true)} // Set hover to true on mouse enter
                        onMouseLeave={() => setIsHoveredService(false)}>
                        <a className="nav-link" href="/">
                         Services
                         {isHoveredS ? (
                            <FaChevronUp style={{ marginLeft: '7px',fontSize:'15px' }} /> // Show up arrow on hover
                            ) : (
                            <FaChevronDown style={{ marginLeft: '7px',fontSize:'15px' }} /> // Show down arrow when not hovered
                          )}
                        </a>
                        {/* Hover container */}
                        <div className="hover-container12 hover-container123 align-with-products">
                          <div style={{ display: 'flex' }}>
                            <div>
                              <img
                                src='image/menuImg.webp'
                                alt="Image1"
                                style={{ width: '200px', height: 'auto', marginRight: '20px' }} // Adjust the size as needed
                              />
                            </div>
                            
                            <div>
                              <a href="/" style={{ color: '#161616', textDecoration: 'none' }}>Service1</a><br></br>
                              <a href="/" style={{ color: '#161616', textDecoration: 'none' }}>Service2</a><br></br>
                              <a href="/" style={{ color: '#161616', textDecoration: 'none' }}>Service3</a>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="nav-item active nav-item-custom12 dropdown-fs ms-4"onMouseEnter={() => setIsHoveredConsulting(true)} // Set hover to true on mouse enter
                        onMouseLeave={() => setIsHoveredConsulting(false)}>
                        <a className="nav-link" href="/">
                         Consulting
                         {isHoveredC ? (
                            <FaChevronUp style={{ marginLeft: '7px',fontSize:'15px' }} /> // Show up arrow on hover
                            ) : (
                            <FaChevronDown style={{ marginLeft: '7px',fontSize:'15px' }} /> // Show down arrow when not hovered
                          )}
                        </a>
                        {/* Hover container */}
                        <div className="hover-container12 hover-container123 align-with-consulting">
                          <div style={{ display: 'flex' }}>
                            <div>
                              <img
                                src='image/menuImg.webp'
                                alt="Image1"
                                style={{ width: '220px', height: 'auto', marginRight: '20px' }} // Adjust the size as needed
                              />
                            </div>
                            
                            <div>
                              <a href="/" style={{ color: '#161616', textDecoration: 'none' }}>Consulting1</a><br></br>
                              <a href="/" style={{ color: '#161616', textDecoration: 'none' }}>Consulting2</a><br></br>
                              <a href="/" style={{ color: '#161616', textDecoration: 'none' }}>Consulting3</a>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="nav-item active nav-item-custom12 dropdown-fs ms-4"  onMouseEnter={() => setIsHovered1(true)} // Set hover to true on mouse enter
                        onMouseLeave={() => setIsHovered1(false)}>
                        <a className="nav-link" href="/">Insight
                           {isHovered1 ? (
                            <FaChevronUp style={{ marginLeft: '7px',fontSize:'15px' }} /> // Show up arrow on hover
                            ) : (
                            <FaChevronDown style={{ marginLeft: '7px',fontSize:'15px' }} /> // Show down arrow when not hovered
                          )}
                        </a>
                        {/* Hover container */}
                        <div className="hover-containerCompany hover-container123 align-with-insight">
                          <div style={{ display: 'flex' }}>
                              <div>
                                <img
                                  src='image/menuImg.webp'
                                  alt="Image1"
                                  style={{ width: '200px', height: 'auto', marginRight: '20px' }} // Adjust the size as needed
                                />
                              </div>
                              
                              <div>
                                <a href="/generative-ai" style={{ color: '#161616', textDecoration: 'none' }}>GenerativeAI</a>
                                <a href="/" style={{ color: '#161616', textDecoration: 'none' }}>Lorem</a>
                                <a href="/" style={{ color: '#161616', textDecoration: 'none' }}>Lorem</a>
                              </div>
                            </div>
                        </div>
                      </li>
                      <li className="nav-item active nav-item-custom12 dropdown-fs ms-4" onMouseEnter={() => setIsHovered(true)} // Set hover to true on mouse enter
                        onMouseLeave={() => setIsHovered(false)} 
                        >   
                        <a className="nav-link" href="/">Company  
                          {isHovered ? (
                            <FaChevronUp style={{ marginLeft: '7px',fontSize:'15px' }} /> // Show up arrow on hover
                            ) : (
                            <FaChevronDown style={{ marginLeft: '7px',fontSize:'15px' }} /> // Show down arrow when not hovered
                          )}
                        </a>
                        {/* Hover container */}
                        <div className="hover-containerCompany hover-container123">
                          <div style={{ display: 'flex' }}>
                              <div>
                                <img
                                  src='image/menuImg.webp'
                                  alt="Image1"
                                  style={{ width: '200px', height: 'auto', marginRight: '20px' }} // Adjust the size as needed
                                />
                              </div>
                              
                              <div>
                                <a href="/about-us" style={{ color: '#161616', textDecoration: 'none' }}>About</a><br></br>
                                <a href="/career" style={{ color: '#161616', textDecoration: 'none' }}>Career</a><br></br>
                              </div>
                            </div>
                        </div>
                      </li>
                        </Nav>
                      </div>
                      <div className='d-block d-sm-block d-lg-none d-md-none'>
                        <div className="navbarmobile">
                          {menuItems.map((item, index) => (
                            <div key={index} className="menu-item-mobile">
                              <div className="menu-title-mobile" onClick={() => toggleItem(index)} style={{
                                color: activeIndex === index ? '#00ADB3' : '#161616', // Change text color to green if active
                                }}>
                                {item.title}
                                <span className="toggle-icon-mobile" style={{
                color: activeIndex === index ? '#00ADB3' : '#161616', // Change icon color to green if active
                marginLeft: '10px', // Add space between title and icon
              }}>{activeIndex === index ? '-' : '+'}</span>
                              </div>
                              
                              {activeIndex === index && (
                              <div className="menu-content-mobile">
                                {/* Map through subcategories and add links */}
                                {item.subcategories.map((subcategory, subIndex) => (
                                  <a style={{textDecoration:'none', color:'#161616'}}
                                    href={subcategory.link} 
                                    key={subIndex} 
                                    className="subcategory-link"
                                  >
                                    <p>{subcategory.name}</p>
                                  </a>
                                ))}
                              </div>
                            )}
                            </div>
                          ))}
                        </div>
                      </div>
                        
                    </Navbar.Collapse>
                
            </Navbar>
        {/* </header> */}
      </div>  
    );
}

export default Header;

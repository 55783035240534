// src/components/Footer.js
import React from 'react';
import './Footer.css'; // Make sure to create this CSS file for styles
import Accordion from 'react-bootstrap/Accordion';


const Footer = () => {
  return (
    <div className='footerheightnone' style={{ backgroundColor: '#191919', paddingTop:'50px',paddingBottom:'65px',height:'100vh' }}>
      <div className="px-5 d-lg-block d-md-block d-sm-none d-none">
      <div className="row">
          <div className="col-lg-3 text-start">
            <p className='figtree fw-600 fs-40 footerLogo' style={{color:'#FFFFFF'}}>Sustainometry</p>
            <div className="row">
                <div className="col-lg-4">
                  <p className='ibm fs-16 fw-500 mb-0 fs-16-footer' style={{color:'#FFFFFF'}}>APAC</p>
                  <p className='ibm fs-14 fw-300 mb-0 fs-14-footer' style={{color:'#FFFFFF99'}}>Australia</p>
                  <p className='ibm fs-14 fw-300 mb-0 fs-14-footer' style={{color:'#FFFFFF99'}}>India</p>
                  <p className='ibm fs-14 fw-300 mb-0 fs-14-footer' style={{color:'#FFFFFF99'}}>Singapore</p>
                </div>
                <div className="col-lg-4">
                  <p className='ibm fs-16 fw-500 mb-0 fs-16-footer' style={{color:'#FFFFFF'}}>Europe</p>
                  <p className='ibm fs-14 fw-300 mb-0 fs-14-footer' style={{color:'#FFFFFF99'}}>Germany </p>
                  <p className='ibm fs-14 fw-300 mb-0 fs-14-footer' style={{color:'#FFFFFF99'}}>UK</p>
                </div>
                <div className="col-lg-4">
                  <p className='ibm fs-16 fw-500 mb-0 fs-16-footer' style={{color:'#FFFFFF'}}>Middle East</p>
                  <p className='ibm fs-14 fw-300 mb-0 fs-14-footer' style={{color:'#FFFFFF99'}}>UAE</p>
                </div>
            </div>
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-8">
            <div className="row mt-4">
              <div className="col-sm-3">
                <p style={{ color:'#FFFFFF' }} className='ibm fw-500 fs-18 fs-18-footer'>Who we are</p>
                <p style={{ color:'#FFFFFF' }} className='ibm fw-400 fs-16 fs-16-footer'>Discover</p>
                <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 mb-0 fs-14-footer'>About Systainometry</p>
                <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 fs-14-footer'>Why Systainometry</p>
                <p style={{ color:'#FFFFFF' }} className='ibm fw-400 fs-16 fs-16-footer'>Talent</p>
                <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 mb-0 fs-14-footer'>Our People</p>
                <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 mb-0 fs-14-footer'>Our Stories</p>
                <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 fs-14-footer'>Join us</p>
              </div>
              <div className="col-sm-3">
                <p style={{ color:'#FFFFFF' }} className='ibm fw-500 fs-18 fs-18-footer'>What we do</p>
                <p style={{ color:'#FFFFFF' }} className='ibm fw-400 fs-16 fs-16-footer'>Systainometry</p>
                <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 mb-0 fs-14-footer'>Our Approach</p>
                <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 fs-14-footer'>Our Services</p>
                <p style={{ color:'#FFFFFF', marginTop:'2rem' }} className='ibm fw-400 fs-16 fs-16-footer'>Our Expertise</p>
                <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 mb-0 fs-14-footer'>Biodiversity</p>
                <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 mb-0 fs-14-footer'>Climate</p>
                <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 mb-0 fs-14-footer'>Land + Culture</p>
                <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 mb-0 fs-14-footer'>Plastic</p>
                <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 mb-0 fs-14-footer'>Water</p>
              </div>
              <div className="col-sm-3">
                <p style={{ color:'#FFFFFF' }} className='ibm fw-500 fs-18 fs-18-footer'>Who we guide</p>
                <p style={{ color:'#FFFFFF' }} className='ibm fw-400 fs-16 fs-16-footer'>Our Key Sectors</p>
                <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 mb-0 fs-14-footer'>Food + Baverage</p>
                <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 mb-0 fs-14-footer'>Cosmetics + Personal Care</p>
                <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 mb-0 fs-14-footer'>Fashion + Sporting Goods</p>
                <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 mb-0 fs-14-footer'>Pharmaceuticals</p>
                <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 fs-14-footer'>Other Sectors</p>
              </div>
              <div className="col-sm-3">
                <p style={{ color:'#FFFFFF' }} className='ibm fw-500 fs-18 fs-18-footer'>What we think</p>
                <p style={{ color:'#FFFFFF' }} className='ibm fw-400 fs-16 fs-16-footer'>Resources</p>
                <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 mb-0 fs-14-footer'>Insights</p>
                <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 mb-0 fs-14-footer'>Reports</p>
                <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 mb-0 fs-14-footer'>Events</p>
                <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 mb-0 fs-14-footer'>Research</p>
              </div>
            </div>
            
          </div>
        </div>
          <hr style={{ color: '#FFFFFF' ,marginTop:'5%'}}></hr>
           <div className="mt-5">
              <p className="footer-copyright fs-16-footer">
                ©2024 Sustainmetric All rights reserved.
              </p>
            </div>
        </div>
          <div className='d-lg-none d-md-none d-block d-sm-block'>
          <section className='px-4'>
            <div>
                <p className='figtree fw-600 fs-40 footerLogo' style={{color:'#FFFFFF'}}>Sustainometry</p>
            </div>
            <div style={{display:'flex', justifyContent:'space-between'}}>
                  <div className="col-lg-4">
                    <p className='ibm fs-16 fw-500 mb-0 fs-16-footer' style={{color:'#FFFFFF'}}>APAC</p>
                    <p className='ibm fs-14 fw-300 mb-0 fs-14-footer' style={{color:'#FFFFFF99'}}>Australia</p>
                    <p className='ibm fs-14 fw-300 mb-0 fs-14-footer' style={{color:'#FFFFFF99'}}>India</p>
                    <p className='ibm fs-14 fw-300 mb-0 fs-14-footer' style={{color:'#FFFFFF99'}}>Singapore</p>
                  </div>
                  <div className="col-lg-4">
                    <p className='ibm fs-16 fw-500 mb-0 fs-16-footer' style={{color:'#FFFFFF'}}>Europe</p>
                    <p className='ibm fs-14 fw-300 mb-0 fs-14-footer' style={{color:'#FFFFFF99'}}>Germany </p>
                    <p className='ibm fs-14 fw-300 mb-0 fs-14-footer' style={{color:'#FFFFFF99'}}>UK</p>
                  </div>
                  <div className="col-lg-4">
                    <p className='ibm fs-16 fw-500 mb-0 fs-16-footer' style={{color:'#FFFFFF'}}>Middle East</p>
                    <p className='ibm fs-14 fw-300 mb-0 fs-14-footer' style={{color:'#FFFFFF99'}}>UAE</p>
                  </div>
              </div>
          </section>
          <section className='mt-4'>
          <Accordion>
          <Accordion.Item eventKey="0" style={{borderLeft: 'none', borderRight: 'none'}}>
            <Accordion.Header style={{borderLeft: 'none', borderRight: 'none'}}>Discover</Accordion.Header>
            <Accordion.Body>
                  <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 fs-14-footer mb-0'>About Systainometry</p>
                  <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 fs-14-footer'>Why Systainometry</p>
                  
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1" style={{borderLeft: 'none', borderRight: 'none'}}>
            <Accordion.Header style={{borderLeft: 'none', borderRight: 'none'}}>Transformation</Accordion.Header>
            <Accordion.Body>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
              minim veniam, quis nostrud exercitation ullamco laboris nisi ut
              aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
              pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
              culpa qui officia deserunt mollit anim id est laborum.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2" style={{borderLeft: 'none', borderRight: 'none'}}>
            <Accordion.Header style={{borderLeft: 'none', borderRight: 'none'}}>Our Key Sectors</Accordion.Header>
            <Accordion.Body>
                <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 mb-0 fs-14-footer'>Food + Baverage</p>
                  <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 mb-0 fs-14-footer'>Cosmetics + Personal Care</p>
                  <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 mb-0 fs-14-footer'>Fashion + Sporting Goods</p>
                  <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 mb-0 fs-14-footer'>Pharmaceuticals</p>
                  <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 fs-14-footer'>Other Sectors</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3" style={{borderLeft: 'none', borderRight: 'none'}}>
            <Accordion.Header style={{borderLeft: 'none', borderRight: 'none'}}>Resources</Accordion.Header>
            <Accordion.Body>
                  <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 mb-0 fs-14-footer'>Insights</p>
                  <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 mb-0 fs-14-footer'>Reports</p>
                  <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 mb-0 fs-14-footer'>Events</p>
                  <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 mb-0 fs-14-footer'>Research</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4" style={{borderLeft: 'none', borderRight: 'none'}}>
            <Accordion.Header style={{borderLeft: 'none', borderRight: 'none'}}>Talent</Accordion.Header>
            <Accordion.Body>
                  <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 mb-0 fs-14-footer'>Our People</p>
                  <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 mb-0 fs-14-footer'>Our Stories</p>
                  <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 fs-14-footer'>Join us</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5" style={{borderLeft: 'none', borderRight: 'none'}}>
            <Accordion.Header style={{borderLeft: 'none', borderRight: 'none'}}>Our Expertise</Accordion.Header>
            <Accordion.Body>
                <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 mb-0 fs-14-footer'>Biodiversity</p>
                  <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 mb-0 fs-14-footer'>Climate</p>
                  <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 mb-0 fs-14-footer'>Land + Culture</p>
                  <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 mb-0 fs-14-footer'>Plastic</p>
                  <p style={{ color:'#FFFFFF99' }} className='ibm fw-300 fs-14 mb-0 fs-14-footer'>Water</p>
            </Accordion.Body>
          </Accordion.Item>
      </Accordion>

              <p className="footer-copyright fs-16-footer pt-5">
                  ©2024 Sustainmetric All rights reserved.
                </p>
          </section>
          </div>
       
    </div>
  );
};

export default Footer;

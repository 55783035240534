import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';




const CommingSoon = () => {
    
  return (
    <div> 
       <div>
        <Header />
      </div>
            <section style={{ background: 'white' , height:'100vh' }} className="container-fluid px-5 py-4 " id="section1">
                <div style={{marginTop:'6rem'}} className="d-flex justify-content-center align-items-center">
                <img style={{width:'400px', height:'400px'}} src="image/comingsoon.jpg" alt="" />
                </div>
            </section>      
        <Footer/>                
    </div>
  );
};

export default CommingSoon;

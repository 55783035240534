
import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';


const About = () => {
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  let lastScrollTop = 0;

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop > lastScrollTop) {
        // Scrolling down, hide the header
        setIsScrollingDown(true);
      } else {
        // Scrolling up, show the header
        setIsScrollingDown(false);
      }

      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // Prevent negative scrolling
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      {/* Apply classes for smooth transitions */}
      <div className={`header-wrapper ${isScrollingDown ? 'hide' : 'show'}`}>
        <Header />
      </div>
      
      <section style={{background:'#E2FEFF'}} className="px-5 py-4 aboutpxremove" id="section1">
                        <div className='row' style={{marginTop:'5%'}}>
                            <div className='col-lg-4'>
                                <p className='ibm fw-300 fs-50 mb-0 careerHeading'style={{color: '#161616'}}>About us</p> 
                                <p className='ibm fw-400 fs-17 careerPara'style={{color: '#16161680'}}>Lorem ipsum dolor sit amet consectetur. Eget eu pulvinar maecenas orci urna ac ut. Sit urna dictum aenean dui. Suspendisse a quam neque faucibus diam cursus hendrerit ac. In gravida integer arcu in amet nunc malesuada duis massa. Phasellus sit suspendisse duis nibh vulputate consectetur. Blandit tempus id dui dignissim eu dolor augue eu. In cursus integer odio praesent pretium. Sagittis in arcu placerat dolor morbi adipiscing.</p> 
                                

                            </div>
                            <div className='col-lg-8 mt-3'>
                            <img className='img-fluid' style={{width:'100%'}} src='image/careerBgImg.svg' alt='HomeImg'/>
                            </div>
                        </div>
        </section>   
          
        <section className='px-5 py-4 aboutpxremove' style={{marginTop:'5%'}}>
          <div>
            <p className='ibm fw-500 fs-30 aboutsec2Heading' style={{color:'#161616'}}>What drives us</p>
          </div>
          <div className='row ms-0 me-0'>
              <div className='col-lg-4 drive-us'>
                  <p className='ibm fw-400 fs-18 aboutsec2Para1' style={{color:'#141414'}}>Lorem +</p>
                  <p className='ibm fw-400 fs-15 aboutsec2Para2' style={{color:'#00000099'}}>Lorem ipsum dolor sit amet consectetur. Tempor a nulla suscipit vel dolor eget. Pellentesque augue porta vitae volutpat sed at. Cursus scelerisque ipsum tempor laoreet habitant urna mi risus. Cursus scelerisque ipsum tempor laoreet habitant urna mi risus. Cursus scelerisque ipsum tempor laoreet habitant urna mi risus.</p>
              </div>
              <div className='col-lg-4 drive-us' >
              <p className='ibm fw-400 fs-18 aboutsec2Para1' style={{color:'#141414'}}>Lorem +</p>
              <p className='ibm fw-400 fs-15 aboutsec2Para2' style={{color:'#00000099'}}>Lorem ipsum dolor sit amet consectetur. Tempor a nulla suscipit vel dolor eget. Pellentesque augue porta vitae volutpat sed at. Cursus scelerisque ipsum tempor laoreet habitant urna mi risus. Cursus scelerisque ipsum tempor laoreet habitant urna mi risus. Cursus scelerisque ipsum tempor laoreet habitant urna mi risus.</p>
              </div>
              <div className='col-lg-4 drive-us' style={{borderRight:'1px solid #888888'}}>
              <p className='ibm fw-400 fs-18 aboutsec2Para1' style={{color:'#141414'}}>Lorem +</p>
              <p className='ibm fw-400 fs-15 aboutsec2Para2' style={{color:'#00000099'}}>Lorem ipsum dolor sit amet consectetur. Tempor a nulla suscipit vel dolor eget. Pellentesque augue porta vitae volutpat sed at. Cursus scelerisque ipsum tempor laoreet habitant urna mi risus. Cursus scelerisque ipsum tempor laoreet habitant urna mi risus. Cursus scelerisque ipsum tempor laoreet habitant urna mi risus.</p>
              </div>
              <div className='col-lg-4 drive-us' style={{borderBottom:'1px solid #888888'}}>
              <p className='ibm fw-400 fs-18 aboutsec2Para1' style={{color:'#141414'}}>Lorem +</p>
              <p className='ibm fw-400 fs-15 aboutsec2Para2' style={{color:'#00000099'}}>Lorem ipsum dolor sit amet consectetur. Tempor a nulla suscipit vel dolor eget. Pellentesque augue porta vitae volutpat sed at. Cursus scelerisque ipsum tempor laoreet habitant urna mi risus. Cursus scelerisque ipsum tempor laoreet habitant urna mi risus. Cursus scelerisque ipsum tempor laoreet habitant urna mi risus.</p>
              </div>
              <div className='col-lg-4 drive-us' style={{borderBottom:'1px solid #888888'}}>
              <p className='ibm fw-400 fs-18 aboutsec2Para1' style={{color:'#141414'}}>Lorem +</p>
              <p className='ibm fw-400 fs-15 aboutsec2Para2' style={{color:'#00000099'}}>Lorem ipsum dolor sit amet consectetur. Tempor a nulla suscipit vel dolor eget. Pellentesque augue porta vitae volutpat sed at. Cursus scelerisque ipsum tempor laoreet habitant urna mi risus. Cursus scelerisque ipsum tempor laoreet habitant urna mi risus. Cursus scelerisque ipsum tempor laoreet habitant urna mi risus.</p>
              </div>
              <div className='col-lg-4 drive-us' style={{borderRight:'1px solid #888888', borderBottom:'1px solid #888888'}}>
              <p className='ibm fw-400 fs-18 aboutsec2Para1' style={{color:'#141414'}}>Lorem +</p>
              <p className='ibm fw-400 fs-15 aboutsec2Para2' style={{color:'#00000099'}}>Lorem ipsum dolor sit amet consectetur. Tempor a nulla suscipit vel dolor eget. Pellentesque augue porta vitae volutpat sed at. Cursus scelerisque ipsum tempor laoreet habitant urna mi risus. Cursus scelerisque ipsum tempor laoreet habitant urna mi risus. Cursus scelerisque ipsum tempor laoreet habitant urna mi risus.</p>
              </div>
          </div>
        </section>
        <section style={{overflow:'hidden'}}>
          <div className='row' style={{marginTop:'5rem'}}> 
            <div className='col-lg-4 '>
              <img  className='img-fluid' src='image/aboutUsImage.svg' alt='About1' />
            </div>
            <div className='col-lg-8 px-4'>
              <p className='ibm fw-400 fs-35 aboutsection3Heading' style={{color:'#161616'}}>A Sustainable Solution, Uniquely Yours!</p>
              <p className='ibm fw-400 fs-15 aboutsection3Para' style={{color:'#16161680'}}>Lorem ipsum dolor sit amet consectetur. Tempor a nulla suscipit vel dolor eget. Pellentesque augue porta vitae volutpat sed at. Cursus scelerisque ipsum tempor laoreet habitant urna mi risus. Risus sit tortor sed tempus at dictum. In lacus vitae enim dignissim egestas purus lectus. Nibh accumsan eu est nulla curabitur ultrices pharetra urna nullam. Enim id condimentum libero ut augue feugiat ultrices vel congue. Orci justo nulla auctor turpis commodo et pharetra nec purus. Aenean ac egestas habitant pellentesque sed massa pretium velit tristique. Turpis viverra volutpat vulputate lorem diam pellentesque aliquet fringilla. Sed habitant integer elit libero turpis id. Dignissim at ut amet amet id scelerisque viverra aliquet risus. Pellentesque .</p>
              <p className='ibm fw-400 fs-15 aboutsection3Para' style={{color:'#16161680'}}>Lorem ipsum dolor sit amet consectetur. Tempor a nulla suscipit vel dolor eget. Pellentesque augue porta vitae volutpat sed at. Cursus scelerisque ipsum tempor laoreet habitant urna mi risus. Risus sit tortor sed tempus at dictum. In lacus vitae enim dignissim egestas purus lectus. Nibh accumsan eu est nulla curabitur ultrices pharetra urna nullam. Enim id condimentum libero ut augue feugiat ultrices vel congue. Orci justo nulla auctor turpis commodo et pharetra nec purus. Aenean ac egestas habitant pellentesque sed massa pretium velit tristique. Turpis viverra volutpat vulputate lorem diam pellentesque aliquet fringilla. Sed habitant integer elit libero turpis id. Dignissim at ut amet amet id scelerisque viverra aliquet risus. </p>
            </div>
          </div>
        </section>
        <section style={{overflow:'hidden'}}>
          <div className='row' style={{marginTop:'7%'}}> 
            <div className='col-lg-8 order-2 order-lg-1 px-4'>
              <p className='ibm fw-400 fs-35  aboutsection3Heading' style={{color:'#161616'}}>A Sustainable Solution, Uniquely Yours!</p>
              <p className='ibm fw-400 fs-15  aboutsection3Para' style={{color:'#16161680'}}>Lorem ipsum dolor sit amet consectetur. Tempor a nulla suscipit vel dolor eget. Pellentesque augue porta vitae volutpat sed at. Cursus scelerisque ipsum tempor laoreet habitant urna mi risus. Risus sit tortor sed tempus at dictum. In lacus vitae enim dignissim egestas purus lectus. Nibh accumsan eu est nulla curabitur ultrices pharetra urna nullam. Enim id condimentum libero ut augue feugiat ultrices vel congue. Orci justo nulla auctor turpis commodo et pharetra nec purus. Aenean ac egestas habitant pellentesque sed massa pretium velit tristique. Turpis viverra volutpat vulputate lorem diam pellentesque aliquet fringilla. Sed habitant integer elit libero turpis id. Dignissim at ut amet amet id scelerisque viverra aliquet risus. Pellentesque .</p>
            </div>
            <div className='col-lg-4 order-1 order-lg-2'>
              <img  className='img-fluid' src='image/aboutUsImage.svg' alt='About1' />
            </div>
          </div>
        </section> 

      <Footer />
    </div>
  );
};

export default About;

